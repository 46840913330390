import i18n, { use } from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./locales/en.json";
import sv from "./locales/sv.json";

const resources = {
    en: {
        translation: en,
    },
    sv: {
        translation: sv,
    },
};

// eslint-disable-next-line react-hooks/rules-of-hooks
use(initReactI18next)
    .init({
        resources,
        lng: "sv",
        fallbackLng: "sv",
        interpolation: {
            escapeValue: false,
        },
        returnNull: false,
    })
    .catch((e) => {
        console.error(e);
    });

export default i18n;
