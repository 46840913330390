import { makeAutoObservable } from "mobx";
import { createClient } from "@/core/helpers";
import { ApiClient } from "@/clients/ApiClient";

export class ToplistStore {
    private client: ApiClient;
    toplist: ToplistEntry[] = [];
    yesterday: ToplistEntry[] = [];
    loading = false;
    categoryFilter: string[] = [];
    authorFilter: string[] = [];
    freeFilter = "";
    current: ToplistMeta | null = null;
    onlyNew = false;

    constructor() {
        makeAutoObservable(this);
        this.client = createClient();
    }

    private async loadByDay(day: string) {
        const { toplist } = await this.client.get<ToplistResponse>(`/podcasts/toplist?date=${day}`);
        return toplist;
    }

    async loadToplist(day: string | undefined = undefined) {
        if (this.loading) {
            return;
        }

        this.toplist = [];
        this.yesterday = [];

        this.loading = true;
        const endpoint = day ? `/podcasts/toplist?date=${day}` : "/podcasts/toplist";
        const response = await this.client.get<ToplistResponse>(endpoint);
        this.toplist = response.toplist;

        this.current = {
            date: response.date,
            count: response.count,
            cached: response.cached,
            latest: response.latest,
            previous: response.previous,
            next: response.next,
        };

        this.loading = false;
    }

    async loadYesterday() {
        if (this.current && this.current.previous) {
            this.yesterday = await this.loadByDay(this.current.previous);
        }
    }

    matchesCategoryFilter(entry: ToplistEntry) {
        if (this.categoryFilter.length === 0) {
            return true;
        }
        return this.categoryFilter.some((filter: string) => {
            return entry.podcast ? entry.podcast.categories.some((cat: Category) => cat.name === filter) : true;
        });
    }

    matchesAuthorFilter(entry: ToplistEntry) {
        if (this.authorFilter.length === 0) {
            return true;
        }
        return this.authorFilter.some((filter: string) => {
            return entry.podcast ? entry.podcast.author.name === filter : true;
        });
    }

    getYesterdaysEntry(podcastId: number): ToplistEntry | undefined {
        return this.yesterday.find((entry: ToplistEntry) => entry.podcast_id === podcastId);
    }

    get entries() {
        return this.toplist
            .filter((entry: ToplistEntry) => this.matchesCategoryFilter(entry))
            .filter((entry: ToplistEntry) => this.matchesAuthorFilter(entry))
            .filter((entry: ToplistEntry) => this.matchesFreeFilter(entry));
    }

    get newEntries() {
        return this.entries.filter((entry: ToplistEntry) => {
            if (!entry.podcast || !entry.podcast.entries_count) {
                return false;
            }
            return entry.podcast.entries_count === 1;
        });
    }

    isNewEntry(entryId: number): boolean {
        return this.newEntries.some((entry: ToplistEntry) => entry.id === entryId);
    }

    get groupedCategories() {
        const groups: GroupedCategories[] = [];

        this.entries.forEach((entry: ToplistEntry) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            entry.podcast &&
                entry.podcast.categories.forEach((cat: Category) => {
                    if (!groups.some((group: GroupedCategories) => group.category.name === cat.name)) {
                        groups.push({ category: cat, count: 1 });
                    } else {
                        const existing = groups.find((group: GroupedCategories) => group.category.name === cat.name);
                        if (existing) {
                            existing.count++;
                        }
                    }
                });
        });

        return groups
            .sort((a: GroupedCategories, b: GroupedCategories) => b.count - a.count)
            .filter((group: GroupedCategories) => group.category.name !== "Podcaster");
    }

    get groupedAuthors() {
        const groups: GroupedAuthor[] = [];

        this.entries.forEach((entry: ToplistEntry) => {
            if (entry.podcast && entry.podcast.author) {
                if (!groups.some((group: GroupedAuthor) => group.author.id === entry.podcast?.author.id)) {
                    groups.push({ author: entry.podcast?.author, count: 1 });
                } else {
                    const existing = groups.find(
                        (group: GroupedAuthor) => group.author.id === entry.podcast?.author.id
                    );
                    if (existing) {
                        existing.count++;
                    }
                }
            }
        });

        return groups.sort((a: GroupedAuthor, b: GroupedAuthor) => b.count - a.count);
    }

    get categories() {
        const cats: Category[] = [];
        this.entries.forEach((entry: ToplistEntry) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            entry.podcast &&
                entry.podcast.categories.forEach((cat: Category) => {
                    if (!cats.find((c: Category) => c.id === cat.id)) {
                        return cat.name !== "Podcaster" && cats.push(cat);
                    }
                });
        });
        return cats;
    }

    toggleCategoryFilter(filter: string) {
        if (this.categoryFilter.includes(filter)) {
            this.categoryFilter = this.categoryFilter.filter((f: string) => f !== filter);
        } else {
            this.categoryFilter.push(filter);
        }
    }

    clearCategoryFilters() {
        this.categoryFilter = [];
    }

    clearAuthorFilters() {
        this.authorFilter = [];
    }

    isInCategoryFilter(name: string) {
        return this.categoryFilter.includes(name);
    }

    isInAuthorFilter(name: string) {
        return this.authorFilter.includes(name);
    }

    toggleAuthorFilter(filter: string) {
        if (this.authorFilter.includes(filter)) {
            this.authorFilter = this.authorFilter.filter((f: string) => f !== filter);
        } else {
            this.authorFilter.push(filter);
        }
    }

    hasFilter() {
        return this.categoryFilter.length > 0 || this.authorFilter.length > 0;
    }

    clearFilter() {
        this.categoryFilter = [];
        this.authorFilter = [];
    }

    search(value: string) {
        this.freeFilter = value;
    }

    private matchesFreeFilter(entry: ToplistEntry) {
        if (this.freeFilter.length === 0) {
            return true;
        } else {
            return entry.podcast && entry.podcast.author
                ? entry.podcast.name.toLowerCase().includes(this.freeFilter.toLowerCase()) ||
                      entry.podcast.author.name.toLowerCase().includes(this.freeFilter.toLowerCase())
                : true;
        }
    }

    setOnlyNew = (value: boolean) => {
        this.onlyNew = value;
    };
}
