type CategoryAd = {
    id: string;
    title: string;
    description: string;
    image: string;
    url: string;
};

const ads: Record<string, CategoryAd> = {
    sport: {
        id: "sport",
        title: "Viaplay - Sportkanalen",
        description: "Se Champions League, Premier League, NHL, Formel 1, PGA Tour och mycket mer.",
        image: "/logos/viaplay.png",
        url: "https://track.adtraction.com/t/t?a=1320006787&as=1628518118&t=2&tk=1",
    },
    naringsliv: {
        id: "naringsliv",
        title: "Mäklarinfo - Bostadspriser",
        description: "Mäklarinfo är guiden för dig som skall sälja eller köpa en bostad. Statistik ned på gatunivå.",
        image: "/logos/maklarinfo.png",
        url: "https://maklarinfo.se?utm_source=hittapodd&utm_medium=banner&utm_campaign=naringsliv",
    },
    spel: {
        id: "spel",
        title: "Amazon Prime",
        description:
            "Få månatliga spel, exklusivt in-game content, Twitch-prenumeration, tillgång till Amazon Prime Video. Fri leverans hem till dörren och mycket mer.",
        image: "/logos/prime.png",
        url: "https://www.amazon.se/tryprimefree?tag=ciceron09-21",
    },
    videospel: {
        id: "videospel",
        title: "PCMR.se - Bygg en speldator",
        description:
            "Bygg en grym speldator! PCMR.se är sajten för dig som vill hitta rätt datorkomponenter till rätt pris och bygga en speldator som passar just dina behov.",
        image: "/logos/pcmr.png",
        url: "https://www.pcmr.se?utm_source=hittapodd&utm_medium=banner&utm_campaign=videospel",
    },
};

export const useEpisodeAds = (categories: Category[]): CategoryAd | null => {
    // Find the first category that has an ad
    const adCategory = categories.find((category) => ads[category.slug]);

    // Check if the ad category exists
    return adCategory ? ads[adCategory.slug] : null;
};
