import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import styles from "./styles/RangeSlider.module.css";

type RangeSliderProps = {
    value: number;
    min: number;
    max: number;
    onChange: (value: number | number[]) => void;
};

export const RangeSlider = ({ value, min, max, onChange }: RangeSliderProps) => {
    return (
        <div className={styles.wrapper}>
            <Slider min={min} max={max} value={value} onChange={onChange} />
        </div>
    );
};
