import { formatDistance, parseISO } from "date-fns";
import { sv } from "date-fns/locale";
import * as localforage from "localforage";
import { ApiClient } from "@/clients/ApiClient";

export const createClient = () => {
    return new ApiClient(import.meta.env.VITE_API_BASE);
};

export const apiClient = createClient();

export const createDurationSec = (duration: string) => {
    const timeFormats = duration.split(":");
    if (timeFormats.length === 2) {
        return parseInt(timeFormats[0]) * 60 + parseInt(timeFormats[1]);
    } else {
        return parseInt(timeFormats[0]) * 3600 + parseInt(timeFormats[1]) * 60 + parseInt(timeFormats[2]);
    }
};

export const createTimeStructure = (sec: number) => {
    if (isNaN(sec)) {
        console.log(sec);
    }
    const h = Math.floor(sec / 3600);
    const m = Math.floor((sec % 3600) / 60);
    const s = Math.floor((sec % 3600) % 60);

    //Add 0 first when only one digit in number.
    const timeFormat = (t: number) => {
        return t / 10 < 1 ? "0" + t : t;
    };
    return `${timeFormat(h)}:${timeFormat(m)}:${timeFormat(s)}`;
};

type DurationType = "sec" | "min" | "hour" | "day" | "week" | "month" | "year";

export const createDuration = (duration: number | string, type: DurationType) => {
    const isNumeric = typeof duration === "number";

    function s(duration: number) {
        return duration;
    }

    const seconds = isNumeric ? s(duration) : createDurationSec(duration.toString());
    switch (type) {
        case "sec":
            return isNumeric ? duration : seconds;
        case "min":
            return isNumeric ? Math.round(duration / 60) : Math.round(seconds / 60);
        case "hour":
            return isNumeric ? Math.round(duration / 3600) : Math.round(seconds / 3600);
        case "day":
            return isNumeric ? Math.round(duration / 86400) : Math.round(seconds / 86400);
        case "week":
            return isNumeric ? Math.round(duration / 604800) : Math.round(seconds / 604800);
        case "month":
            return isNumeric ? Math.round(duration / 2592000) : Math.round(seconds / 2592000);
        case "year":
            return isNumeric ? Math.round(duration / 31536000) : Math.round(seconds / 31536000);
    }
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createDurationMin = (duration: any) => {
    return createDuration(duration, "min");
};

export const bytesToSize = (bytes: number) => {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    const i = Math.round(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i)) + " " + sizes[i];
};

export const getAvgEpisodeLength = (episodes: Episode[]) => {
    const lengths = episodes.map((e: Episode) => createDurationSec(e.length)).filter((item: number) => !isNaN(item));
    const sum = lengths.reduce((a: number, b: number) => a + b, 0);
    const avg = sum / lengths.length || 0;
    return Math.round(avg / 60);
};

export const getAvgPublishingTimeSpan = (episodes: Episode[]) => {
    const times = episodes.slice(0, 10).map((episode: Episode) => new Date(episode.published).getTime());
    const diffs = times.slice(1).map((n: number, index: number) => n - times[index]);
    const sum = diffs.reduce((a: number, b: number) => a + b, 0);
    const avg = Math.abs(sum / diffs.length || 0);
    const diff = avg / 1000 / 60 / 60 / 24;

    if (diff < 1.2) {
        return "Dagligen";
    }
    if (diff < 3) {
        return "Varannan dag";
    }
    if (diff < 8) {
        return "Veckovis";
    }

    if (diff < 17) {
        return "Varannan vecka";
    }

    return Math.round(30 / diff) + " ggr/månad";
};

export const filterTitle = (title: string) => {
    return title.trim();
};

export const isValidURL = (str: string): boolean => {
    const a = document.createElement("a");
    a.href = str;
    return !!a.host && a.host !== window.location.host;
};

export const truncate = (text: string, len: number, append = "...") => {
    if (text.length + append.length > len) {
        return text.substring(0, len) + append;
    }
    return text;
};

export enum ImageSizes {
    Tiny = 32,
    Thumb = 64,
    Small = 240,
    Medium = 480,
    Large = 600,
}

export const getPodcastImage = (podcast: Podcast, size: ImageSizes = ImageSizes.Medium) => {
    return `https://yetric.se/img/${podcast.slug}-${size}x${size}.webp`;
};

export const getEpisodeImage = (episode: Episode, podcast: Podcast) => {
    return episode.art.length > 0 ? episode.art : getPodcastImage(podcast);
};

export const filterText = (txt: string) => {
    while (txt.indexOf("__") > 0) {
        txt = txt.replace("__", "_");
    }
    while (txt.indexOf("--") > 0) {
        txt = txt.replace("--", "_");
    }

    txt = txt.replace("_", "");
    txt = txt.replace(/&nbsp;/g, " ");
    return txt;
};

const langs: {
    [key: string]: string[];
} = {
    sv: ["sv-se", "sv", "se", "sv-SE"],
    en: ["en", "en-us", "en-uk", "en-US", "en-UK"],
};

const languageNames: {
    [key: string]: string;
} = {
    sv: "Svenska",
    en: "English",
};

export const localeToShort = (locale: string) => {
    for (const languageShort in langs) {
        if (langs[languageShort].indexOf(locale) > -1) {
            return languageShort;
        }
    }
    return "sv";
};

export const languageLookup = (locale: string) => {
    for (const languageShort in langs) {
        if (langs[languageShort].indexOf(locale) > -1) {
            return languageNames[languageShort];
        }
    }
    return locale;
};
export const timeAgo = (date: string, suffix = true) => {
    const publishedDate = parseISO(date);
    return formatDistance(publishedDate, new Date(), {
        addSuffix: suffix,
        locale: sv,
    });
};

export const onlyUniques = (value: unknown, index: number, self: unknown) => {
    if (!Array.isArray(self)) {
        return false;
    }
    return self.indexOf(value) === index;
};

export const remoteSave = async (key: string, item: unknown) => {
    const clientId: string = window.clientId ?? "";
    await apiClient.post("/remote/storage", {
        key,
        item,
        clientId,
    });
};

export const remoteDelete = (key: string, item: unknown) => {
    console.log("remoteDelete", key, item);
};

export const localSave = async (key: string, item: unknown, max = 10, sync = true) => {
    const coll: unknown = await localforage.getItem(key);
    const tmp = coll ?? [];

    if (!Array.isArray(tmp)) {
        return;
    }

    tmp.unshift(item);
    while (tmp.length > max) {
        tmp.pop();
    }
    const uniques = tmp.filter(onlyUniques);
    await localforage.setItem(key, uniques);
    if (sync) {
        await remoteSave(key, uniques);
    }
};

export const localGet = async (key: string) => {
    const coll = await localforage.getItem(key);
    return coll ? coll : null;
};

export const localRemove = async (key: string, item: unknown) => {
    const coll: unknown = await localforage.getItem(key);
    const tmp = coll ?? [];

    if (!Array.isArray(tmp)) {
        return;
    }

    const index = tmp.indexOf(item);
    if (index > -1) {
        tmp.splice(index, 1);
    }
    await localforage.setItem(key, tmp);
    await remoteSave(key, tmp);
};

export const arrAvg = (arr: number[]) => {
    return Array.isArray(arr) ? arr.reduce((a, b) => a + b, 0) / arr.length : 0;
};

export const storage = {
    get: localforage.getItem,
    set: localforage.setItem,
};

// Shuffle array of any type using generic
export const shuffle = <T>(array: T[]): T[] => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
};

export const spotifyProtocolToHttp = (url: string) => {
    const parts = url.split(":");
    if (parts.length > 1) {
        return `https://open.spotify.com/${parts[1]}/${parts[2]}`;
    }
    return url;
};

export function getFirstCategory(categories: Category[]) {
    let [cat] = categories;
    if (categories.length > 1 && categories[0].slug === "podcaster") {
        // get last category (more specific)
        cat = categories[categories.length - 1];
    }

    return {
        label: cat.name,
        link: `/kategori/${cat.slug}`,
    };
}
