import { makeAutoObservable } from "mobx";
import FingerprintJS from "@fingerprintjs/fingerprintjs-pro";
import Pubsub from "pubsub-js";
import localforage from "localforage";
import { createClient, localGet, localRemove, localSave, storage } from "@/core/helpers";
import { ApiClient } from "@/clients/ApiClient";
import { logout } from "@/core/firebase";

enum Keys {
    GDPR = "hp_gdpr",
    PING = "hp_ping",
}

interface LoggedInUser {
    displayName: string | null;
    photoURL: string | null;
    email: string | null;
    uid: string;
    provider: string;
    token?: string;
}

function fingerprintJSPolyfill() {
    console.debug("Using fingerprintjs polyfill");
    return {
        visitorId: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
        confidence: {
            score: 0,
        },
        ipLocation: {
            country: {
                code: "",
            },
            latitude: 0,
            longitude: 0,
            city: {
                name: "",
            },
            postalCode: "",
            timezone: "",
        },
    };
}

export class UserStore {
    private client: ApiClient;
    gdprApproved = false;
    userId = "";
    lastEpisodes: Episode[] = [];
    lastPodcasts: Podcast[] = [];
    episodeIds = "";
    podcastIds = "";
    isLoggedIn = false;
    authing = false;
    trusted = false;
    score = 0;
    country = "";
    latitude: number | undefined = undefined;
    longitude: number | undefined = undefined;
    user: LoggedInUser | null = null;
    city = "";
    postalCode = "";
    timeZone = "";
    showGdpr = false;
    sessionId = "";

    loadingEpisodeColl = false;

    constructor() {
        makeAutoObservable(this);
        this.client = createClient();

        let existing = sessionStorage.getItem("hp_session_id");
        if (!existing && crypto) {
            try {
                existing = crypto.randomUUID();
                sessionStorage.setItem("hp_session_id", existing);
            } catch (e) {
                console.error(e);
            }
        }

        if (!existing) {
            existing = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
            sessionStorage.setItem("hp_session_id", existing);
        }

        this.sessionId = existing;

        (async () => {
            const gdprApproved = await storage.get(Keys.GDPR);
            this.gdprApproved = !!gdprApproved;

            if (!gdprApproved) {
                this.showGdpr = true;
            }
        })();

        // eslint-disable-next-line import/no-named-as-default-member
        const fpPromise = FingerprintJS.load({
            apiKey: "BVgFTr5CPpanlGrWc3gX",
            region: "eu",
        });

        (async () => {
            let result;
            try {
                const fp = await fpPromise;
                result = await fp.get({
                    extendedResult: true,
                });
            } catch (e) {
                console.error(e);
                // Fallback solution if Fingerprint doesn't work
                result = fingerprintJSPolyfill();
            }
            this.trusted = true;
            this.score = result.confidence.score;
            this.userId = result.visitorId;
            this.country = result.ipLocation?.country?.code || "";
            this.latitude = result.ipLocation?.latitude;
            this.longitude = result.ipLocation?.longitude;
            this.city = result.ipLocation?.city?.name || "";
            this.postalCode = result.ipLocation?.postalCode || "";
            this.timeZone = result.ipLocation?.timezone || "";
            window["clientId"] = result.visitorId;
        })();
    }

    async ping(episodeId: number, posSeconds: number) {
        await localforage.setItem(Keys.PING, {
            id: episodeId,
            pos: posSeconds,
        });
    }

    async suggest(url: string) {
        if (this.userId.length === 0) return;
        const payload = {
            client: this.userId,
            url,
        };
        await this.client.post("/podcasts/suggest", payload);
    }

    async follow(podcast: Podcast | null) {
        if (!podcast) return;
        await localSave("hp_followed_podcasts", podcast.id, 5);
        await this.trackUserEvents("follow", {
            id: podcast.id,
        });
    }

    async unfollow(podcast: Podcast | null) {
        if (!podcast) return;
        await localRemove("hp_followed_podcasts", podcast.id);
        await this.trackUserEvents("unfollow", {
            id: podcast.id,
        });
    }

    isFollowing(id: number) {
        return id === id;
    }

    async latestEpisodes() {
        if (this.loadingEpisodeColl) return;

        this.loadingEpisodeColl = true;
        const episodeIds: unknown = await localGet("hp_episode_history");
        if (episodeIds && Array.isArray(episodeIds) && episodeIds.length > 0) {
            const ids = episodeIds.join(",");
            if (this.episodeIds === ids) {
                return;
            }
            const { episodes } = await this.client.get<EpisodesResponse>(
                "/episodes/collection?ids=" + episodeIds.join(",")
            );
            this.lastEpisodes = episodes;
            this.episodeIds = ids;
        }
        this.loadingEpisodeColl = false;
    }

    async latestPodcasts() {
        const podcastIds: unknown = await localGet("hp_pod_history");
        if (podcastIds && Array.isArray(podcastIds) && podcastIds.length > 0) {
            const ids = podcastIds.join(",");
            if (this.podcastIds === ids) {
                return;
            }
            const { podcasts } = await this.client.get<PodcastsResponse>("/podcasts/collection?ids=" + ids);
            this.lastPodcasts = podcasts;
            this.podcastIds = ids;
        }
    }

    async trackUserEvents(action: string, payload: unknown = {}) {
        await this.client.post("/user/action", {
            action,
            payload,
            client: this.userId ?? "",
        });
        Pubsub.publish(`user/${action}`);
    }

    setLoggedIn(loggedIn: boolean) {
        this.isLoggedIn = loggedIn;
    }

    setAuthing(authing: boolean) {
        this.authing = authing;
    }

    async logout() {
        this.authing = true;
        await logout();
        this.setLoggedIn(false);
        await this.setUser(null);
        this.authing = false;
    }

    async setUser(user: LoggedInUser | null) {
        if (!user) return;
        this.user = {
            email: user.email,
            provider: user.provider,
            uid: user.uid,
            displayName: user.displayName,
            photoURL: user.photoURL,
        };
        if (user) {
            await this.client.post("/user/auth", {
                user,
                id: this.userId,
                trusted: this.trusted,
                country: this.country,
                lat: this.latitude,
                lng: this.longitude,
                score: this.score,
                city: this.city,
                zipcode: this.postalCode,
                tz: this.timeZone,
                clientTime: new Date().getTime(),
                gdpr: this.gdprApproved,
                email: user.email,
                name: user.displayName,
                uid: user.uid,
                token: user.token ?? "",
            });
        }
        this.setLoggedIn(true);
    }

    async setGdprApproved(approved: boolean) {
        await storage.set(Keys.GDPR, approved);
        this.gdprApproved = approved;
    }
}
